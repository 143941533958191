var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    night: _vm.grimoire.isNight,
    static: _vm.grimoire.isStatic
  },style:({
    backgroundImage: _vm.grimoire.background
      ? `url('${_vm.grimoire.background}')`
      : ''
  }),attrs:{"id":"app","tabindex":"-1"},on:{"keyup":_vm.keyup}},[(_vm.grimoire.background && _vm.grimoire.background.match(/\.(mp4|webm)$/i))?_c('video',{attrs:{"id":"background","src":_vm.grimoire.background,"autoplay":"","loop":""}}):_vm._e(),_c('dialog',{ref:"dialog"},[_vm._v(" server gave undecodable data. invalid password or something is wrong... "),_c('a',{ref:"dialoglink"},[_vm._v("download state")]),_c('br'),_vm._m(0)]),_c('div',{staticClass:"backdrop"}),_c('transition',{attrs:{"name":"blur"}},[(!_vm.players.length)?_c('Intro'):_vm._e(),(_vm.players.length && !_vm.session.nomination)?_c('TownInfo'):_vm._e(),(_vm.session.nomination)?_c('Vote'):_vm._e()],1),_c('TownSquare'),_c('Menu',{ref:"menu"}),_c('EditionModal'),_c('FabledModal'),_c('RolesModal'),_c('ReferenceModal'),_c('NightOrderModal'),_c('VoteHistoryModal'),_c('GameStateModal',{ref:"gamestate"}),_c('Gradients'),_c('span',{attrs:{"id":"version"}},[_vm._v("v"+_vm._s(_vm.version))])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"method":"dialog"}},[_c('button',[_vm._v("OK")])])
}]

export { render, staticRenderFns }